<template>
  <b-card
    no-body
    class="card-statistics"
    :class="{
      'cursor-pointer': $can('read', resource),
    }"
    @click="onClick"
  >
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-header class="pb-0">
        <b-card-title>{{ title }}</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col cols="12" md="4" class="pb-2 pb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="`light-${varaint}`">
                  <feather-icon size="24" icon="ShoppingCartIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" :class="`text-${varaint}`">
                  {{ quantity }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ quantityLabel }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="12" md="4" class="pb-2 pb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="`light-${varaint}`">
                  <feather-icon size="24" icon="BoxIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" :class="`text-${varaint}`">
                  {{ volume | number }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ volumeLabel }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="12" md="4">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="`light-${varaint}`">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" :class="`text-${varaint}`">
                  {{ amount | currency }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ amountLabel }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    varaint: {
      type: String,
      default: "primary",
    },
    quantity: {
      type: Number,
      default: 0,
    },
    quantityLabel: {
      type: String,
      default: "",
    },
    volume: {
      type: Number,
      default: 0,
    },
    volumeLabel: {
      type: String,
      default: "",
    },
    amount: {
      type: Number,
      default: 0,
    },
    amountLabel: {
      type: String,
      default: "",
    },
    resource: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.$can("read", this.resource)) {
        this.$emit("click");
      }
    },
  },
};
</script>
