<template>
  <div>
    <load-profile></load-profile>

    <section id="dashboard-analytics">
      <date-filter :loading="loading" v-model="query.date" />
      <b-row>
        <b-col xl="6">
          <statistic-card
            :loading="loading"
            :title="$t('field.salesOrder')"
            :quantity="data.pendingSalesOrder"
            :quantityLabel="$t('field.order')"
            :volume="data.pendingSalesOrderVolume"
            :volumeLabel="$t('field.salesVolume')"
            :amount="data.pendingSalesOrderAmount"
            :amountLabel="$t('field.amount')"
            resource="agent-sales-order"
            @click="
              viewOrder('list-sales-order', { orderDate: query.orderDate })
            "
          />
        </b-col>
        <b-col xl="6">
          <statistic-card
            :loading="loading"
            :title="$t('field.salesReturnOrder')"
            :quantity="data.pendingSalesReturnOrder"
            :quantityLabel="$t('field.order')"
            :volume="data.pendingSalesReturnOrderVolume"
            :volumeLabel="$t('field.salesVolume')"
            :amount="data.pendingSalesReturnOrderAmount"
            :amountLabel="$t('field.amount')"
            resource="agent-sales-return-order"
            varaint="danger"
            @click="
              viewOrder('list-sales-return-order', {
                orderDate: query.orderDate,
              })
            "
          />
        </b-col>
        <b-col xl="6">
          <statistic-card
            :loading="loading"
            :title="$t('field.invoice')"
            :quantity="data.invoice"
            :quantityLabel="$t('field.invoice')"
            :volume="data.invoiceVolume"
            :volumeLabel="$t('field.salesVolume')"
            :amount="data.invoiceAmount"
            :amountLabel="$t('field.amount')"
            resource="agent-sales-invoice"
            @click="
              viewOrder('list-sales-invoice', { postingDate: query.orderDate })
            "
          />
        </b-col>
        <b-col xl="6">
          <statistic-card
            :loading="loading"
            :title="$t('field.creditMemo')"
            :quantity="data.creditMemo"
            :quantityLabel="$t('field.creditMemo')"
            :volume="data.creditMemoVolume"
            :volumeLabel="$t('field.salesVolume')"
            :amount="data.creditMemoAmount"
            :amountLabel="$t('field.amount')"
            resource="agent-sales-credit-memo"
            varaint="danger"
            @click="
              viewOrder('list-sales-credit-memo', {
                postingDate: query.orderDate,
              })
            "
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import DateFilter from "./DateFilter.vue";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import StatisticCard from "./StatisticCard.vue";

const DpDashboardRepository = Repository.get("dpDashboard");

export default {
  components: {
    BRow,
    BCol,

    DateFilter,
    StatisticCard,
  },
  data() {
    return {
      loading: false,
      query: {
        date: this.$route.query.date || "thisMonth",
        orderDate: this.$route.query.orderDate || [],
      },
      data: {
        pendingSalesOrder: 0,
        pendingSalesReturnOrder: 0,
        invoice: 0,
        creditMemo: 0,
        pendingSalesOrderVolume: 0,
        pendingSalesReturnOrderVolume: 0,
        invoiceVolume: 0,
        creditMemoVolume: 0,
        salesOrderAmount: 0,
        salesReturnOrderAmount: 0,
        salesInvoiceAmount: 0,
        salesCreditMemoAmount: 0,
      },
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    "query.date"(value) {
      this.query.orderDate = calculateDatesByPeriod(value);
      this.index();
    },
  },
  mounted() {
    this.query.orderDate = calculateDatesByPeriod(this.query.date);
    this.index();
  },
  methods: {
    index() {
      this.loading = true;
      this.updateQuerySting();
      DpDashboardRepository.summary({
        fromDate: this.query.orderDate[0],
        toDate: this.query.orderDate[1],
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...this.data, ...data };
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
    viewOrder(route, query = {}) {
      this.$router.push({
        name: route,
        query: query,
      });
    },
  },
};
</script>
